import "./src/css/style.css";
import "./src/css/global.css";

// gatsby-browser.js
export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
  }) => {
    console.log({ location });

    if (location.hash) {
      return false;
    }

    return true;
  };
